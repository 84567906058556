import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../images/ChurchNearMe_Big.jpg"
import Layout from "../components/layoutMainCustom"
import PageHeaders from "../components/pageHeaders"
import SEO from "../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const MainButton = styled(Link)`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Find a Church Near You"
    SecondText="one church. Multiple Locations."
  >
    <SEO title="Church Near Me" />
    <PageHeaders
      css={css`
        display: flex;
        padding-bottom: 0.5rem;
      `}
    >
      Cross Church Surprise
      <MainButton to="/surprise">Visit Campus</MainButton>
    </PageHeaders>
    <HeaderText>Location</HeaderText>
    <ParagraphText>
      Cross Church Surprise is located at 17475 W. Bell Road in Surprise, AZ.
      The building sits on the corner of Bell Road and 175th Avenue just 1 mile
      west of the 303.
    </ParagraphText>
    <HeaderText>Service Times</HeaderText>
    <ParagraphText>
      This location offers three service times in total. This location offers
      three services on Sunday mornings at 9:00am and 10:30am. Our 10:30am
      worship service is also available live online. The worship experience is
      typically between 60-65 minutes in length.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        padding-bottom: 0.5rem;
        margin-top: 4rem;
      `}
    >
      Cross Church Phoenix
      <MainButton to="/phoenix">Visit Campus</MainButton>
    </PageHeaders>
    <HeaderText>Location</HeaderText>
    <ParagraphText>
      Cross Church Phoenix is located at 1827 W. Grovers Ave. in Phoenix, AZ
      85023. The building sits on the corner of 19th Avenue and Grovers just
      south of Union Hills.
    </ParagraphText>
    <HeaderText>Service Times</HeaderText>
    <ParagraphText>
      This location offers two service times at 9:00am and 10:30am on Sunday
      mornings. Our 10:30am worship service is also available live online. The
      worship experience is typically between 60-65 minutes in length.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        padding-bottom: 0.5rem;
        margin-top: 4rem;
      `}
    >
      Cross Church Cornville
      <MainButton to="/cornville">Visit Campus</MainButton>
    </PageHeaders>
    <HeaderText>Location</HeaderText>
    <ParagraphText>
      Cross Church Cornville is located at 11340 E Circle Drive in Cornville, AZ
      86325. The building sits just north of Cornville Road and east of S. Page
      Springs Road.
    </ParagraphText>
    <HeaderText>Service Times</HeaderText>
    <ParagraphText>
      This location offers two service times at 9:00am and 10:30am on Sunday
      mornings. Our 10:30am worship service is also available live online.The
      worship experience is typically between 60-65 minutes in length.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        padding-bottom: 0.5rem;
        margin-top: 4rem;
      `}
    >
      Cross Church Phoenix
      <MainButton to="/elmirage">Visit Campus</MainButton>
    </PageHeaders>
    <HeaderText>Location</HeaderText>
    <ParagraphText>
      Cross Church El Mirage is located at 11006 N. El Mirage Road in El Mirage,
      AZ 85335. The building sits just north of Peoria Ave.
    </ParagraphText>
    <HeaderText>Service Times</HeaderText>
    <ParagraphText>
      This location offers two service times at 9:00am and 10:30am on Sunday
      mornings. Our 10:30am worship service is also available live online. The
      worship experience is typically between 60-65 minutes in length.
    </ParagraphText>
  </Layout>
)

export default IndexPage
